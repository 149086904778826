<template>
  <div id="HistoryMain">
    <h1 class="font-bold text-2xl text-left">History</h1>
		<p>Below are all of your orders from Bark Badge directly</p>
    <div class="invoices">
      <!-- <div class="invoice">
        <div class="flex">
          <p class="success-text">
            <img src="@/assets/icons/history/calendar.svg" class="check-icon"/> Upcoming Charge</p>
          <p class="paid-amount no-charge">${{(choronlizedList[0].total_amount/100).toLocaleString()}} (No Charge Applied)</p>
          <p>{{relativeDate(choronlizedList[0].created + 2592000)}}</p>
        </div>
      </div> -->

      <div v-for="(inv, idx) in choronlizedList" :key="idx">
				<div class="invoice flex justify-between">
					<!-- {{inv}} -->
	        <div class="flex">
	          <p class="success-text"><img src="@/assets/icons/history/check.svg" class="check-icon"/> Successful Charge</p>
	          <p class="paid-amount">${{(inv.total_amount/100).toLocaleString()}}</p>
	          <p>{{relativeDate(inv.created) }}</p>
	        </div>

	        <button class="text-blue-300" @click="displayDetails(inv.id)">View Details</button>
				</div>

				<div class="details-dropdown text-left" v-if="order_details == inv.id">
					<small class="text-gray-400 italic text-left">{{inv.confirmation_number}}</small>
					<section class="details-info">
						<!-- devices -->
					<section class="">
						<div class="flex items-center border-b-2 border-gray-300 border-dashed py-2">
							<img class="w-8 mr-3  ml-2" src="@/assets/icons/order-invoice/package.svg" />
							<p class="text-xl">Cargo [{{inv.products.length}}]</p>
						</div>
						<div v-if="inv.products" class="product-box ml-4"
							:class="{'border-b-2 border-gray-200': inv.products.length > 3}">
						<div class="relative ml-10" v-for="(item, idx) in inv.products" :key="'product_'+idx">
							<div class="flex items-center justify-between cursor-pointer text-left">
								<div class="flex items-center">
									<img class=" h-10 w-10" :src="item.uri" />
									<div class="text-sm font-medium text-gray-900 my-5">
									<p>{{ item.name }}</p>
									<p class="text-theme">qty. {{ item.qty }}</p>
									</div>
								</div>

								<div class="text-xl">
								${{ (item.qty * 11.25).toLocaleString() }}
								</div>
							</div>
						</div>
						</div>
						<div class="text-left" v-else>
							<p class="italic text-gray-500">Your Cart is Empty</p>
						</div>
					</section>
					<!-- address -->
					<section class="text-left">
						<div class="flex items-center border-b-2 border-gray-300 border-dashed py-2">
							<img class="w-8 mr-3" src="@/assets/icons/order-invoice/shipping.svg" />
							<p class="text-xl">Shipping</p>
						</div>

						<div class="flex flex-col ml-10" >
							<!-- <div class="my-4">
								<p class="text-purple-300">Customer Name</p>
								<p>{{inv.name}}</p>
							</div> -->
							<div class="my-4" >
								<p class="text-purple-300">Delivery Address</p>
								<p class="font-bold">{{inv.delivery_address.name}}</p>
								<p>{{inv.delivery_address.line1}}, {{inv.delivery_address.line2}}</p>
								<p>{{inv.delivery_address.city}}, {{inv.delivery_address.state}} {{inv.delivery_address.country}}</p>
								<p>{{inv.delivery_address.zip}}</p>
							</div>

							<div class="my-4">
								<p class="text-purple-300">Expected Delivery Date</p>
								<p>10-12 Business Days</p>
							</div>
						</div>
					</section>
				 </section>
				 <div class="footer w-full text-center">
					 <a class="  mx-auto text-blue-400 my-2" :href="`/order/${inv.id}`">Go to Invoice</a>
				 </div>
				</div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import mixins from '@/mixins'
import order_history from '@/assets/json/order_history.json'
import {mapState} from 'vuex'

export default{
  name: 'HistoryMain',
  components:{
  },
  mixins:[mixins],
  data(){
    return{
      order_list: order_history,
			order_details: ''
    }
  },
  methods:{
		displayDetails(o_id){
			if(this.order_details != ''){
				this.order_details = ''
			}else{
				this.order_details = o_id
			}
		},
    getOrderHistory(){
      const config ={
				headers: {
					userID: this.userID
				}
			}
			axios.get('https://petsproject.skylineexecutive.com/wholesale/orders/all', config).then((res)=>{
				this.order_list = res.data.result
			}).catch((err)=>{
				console.log(err)
			})
    }
  },
  created(){
    // this.getInvoiceData()
		this.getOrderHistory()
  },
  computed:{
    choronlizedList(){
      let chrono_list = this.order_list
      return chrono_list.sort((a, b) => b.created - a.created)
    },
				...mapState(['userID']),
    // ...mapState(['userID'])
  }
}
</script>
<style scoped>
.check-icon{
  width: 1.5rem;
  margin-right: .5rem;
}
.success-text{
  display: flex;
  align-items:center;
  font-size: 12px;
  color: #666;
}
.dark .success-text{
  color: #fff;
}
.invoice{
  background-color: #eee;
  border-radius: 5px;
  margin: 1rem 0;
  padding: .5rem;
  width: 90%;
}
.dark .invoice{
  background-color: #0f1820 !important;
}
.paid-amount{
  color: var(--link-light);
  margin: 0 1rem;
}
.no-charge{
  color: #aaa;
}
.details-dropdown{
	border:1px solid #eee;
	width: 90%;
	min-height: 10vh;
	border-radius: 10;
}
.details-info{
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr;
	grid-column-gap: 4px;
	grid-row-gap: 20px;
	justify-items: stretch;
	align-items: stretch;
}
.product-box{
	overflow-y: scroll;
	max-height: 180px;
}
@media screen and (max-width: 600px) {
}
@media screen and (max-width: 400px) {
}
</style>
