<template>
  <div id="MarketingMain">
    <h1 class="font-bold text-2xl text-left mb-5">Marketing Materials</h1>

    <div class="media-collection">
      <div v-for="(mat, idx) in materials" :key="idx" >
        <div class="material-thumbnail"
              :style="{'background-image': 'url(' + mat.uri + ')'}"
              :class="{'bg-black': mat.background == 'black'}">
        </div>
        <div class="material-info">
          <p class="title text-sm my-3 ">{{mat.title}}</p>
          <div class="flex justify-evenly items-center">
            <a :href="mat.uri"  @click.prevent="downloadItem(mat)" class="bg-green-500 text-white rounded py-2 px-4">Download </a>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import axios from 'axios'
import marketing_materials from '@/assets/json/marketing_materials.json'

export default{
  name: 'MarketingMain',
  components:{
  },
  data(){
    return{
      materials: marketing_materials
    }
  },
  methods:{
    downloadItem ({ uri, title }) {
    axios.get(uri, { responseType: 'blob' })
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = title
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch(console.error)
  }
  },
  mounted(){
    // this.getInvoiceData()
  },
}
</script>
<style scoped>
.media-collection{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-items: stretch;
  align-items: stretch;
}
.material-thumbnail{
  position:relative;
  width: 12rem;
  height: 6rem;
  margin: 0 auto;
  border-radius: 10px;
  overflow:hidden;
  border: 5px solid var(--bg-default);
  /* Create the parallax scrolling effect */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
}
.material-info .title{
  font-size: 1rem;
}
@media screen and (max-width: 600px) {
}
@media screen and (max-width: 400px) {
}
</style>
