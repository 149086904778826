<template>
  <div class="home">
    <IntroductionSection />
    <OurProductsList />
  </div>
</template>

<script>
// @ is an alias to /src
import IntroductionSection from '@/views/our-products/IntroductionSection.vue'
import OurProductsList from '@/views/our-products/OurProductsList.vue'
import {mapState} from 'vuex'
export default {
  name: 'Home',
  components: {
    IntroductionSection,
    OurProductsList
  },
  computed:{
    ...mapState(['userID'])
  },
  created(){
    if( this.userID == ''){
      this.$router.push({name: 'GateAuth'})
    }
  }
}
</script>
