<template>
  <div  class="bg-white p-5 w-11/12 mx-auto rounded-3xl box"  v-if="!processing && !successAlert">
    <h1 class="text-4xl font-bold">Login</h1>
    <h2 class="welcome-text text-xl my-2 font-bold">Welcome back</h2>

    <p class="text-lg text-red-500">{{feedback}}</p>
    <div class="block w-full text-left mx-auto rounded-3xl " >
        <div class="mb-4" v-for="(field,idx) in form_fields" :key="idx">
          <label class="block text-gray-500 text-sm font-bold mb-2"  for="username">
            {{field.label}} <span class="text-red-200" ></span>
          </label>
          <input
            class=" shadow appearance-none border rounded w-full py-2 px-3 text-gray-500 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text"
            :class="{'border-red-500': reminder}"
            v-model="field.value" :placeholder="field.placeholder"
          />
        </div>
        <div class="mb-4" >
          <label class="block text-gray-500 text-sm font-bold mb-2"  for="password">Password</label>
          <input
            class=" shadow appearance-none border rounded w-full py-2 px-3 text-gray-500 leading-tight focus:outline-none focus:shadow-outline" id="password" type="password" v-model="password" placeholder="Password" :class="{'border-red-500': reminder}"
          />
        </div>
    </div>
    <p>Don't have an account? <a href="/register" class="text-theme">Apply Now</a></p>
    <div class="flex justify-center items-center">
      <button class="submitWait bg-black" @click.prevent="validate()">
        Login
      </button>
    </div>
  </div>

  <div class=" bg-white p-12 w-11/12 md:w-8/12 lg:5/12 mx-auto rounded-3xl box" v-else-if="processing">
    <p class="italic">Please Wait...</p>
  </div>
  <div class=" bg-white p-12 w-11/12 md:w-8/12 lg:5/12 mx-auto rounded-3xl box text-center" v-else-if="!processing && successAlert">
    <h1 class="text-3xl lg:text-5xl my-10 font-bold mx-auto text-green-400">You are on the Waitlist!</h1>
    <p  class="text-lg lg:text-3xl my-5 text-gray-600">Be on the look out in your email when we launch! We will be in touch!</p>
    <p  class="text-lg lg:text-3xl text-gray-600" >In the meantime feel free to follow us on social media!</p>
  </div>
</template>
<script>
// import axios from 'axios'
import {fb} from '@/_firebase/init'
import {mapState, mapMutations} from 'vuex'

export default {
  name: "Login",
  components: {},
  data() {
    return {
      processing: false,
      successAlert: false,
      status: '',
      reminder: false,
      regStep: 0,
      feedback: '',
      form_fields: [
        {label: 'Email', placeholder:'my@email.com', value:'', input_type:'text', isRequired:false},
      ],
      password: '',
      bkgd_img: 'https://images.unsplash.com/photo-1504194782011-e78a3bf9d300?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
    };
  },
  methods: {
    ...mapMutations(['SET_USER_ID', 'SET_TOKEN']),
    validate(){
      if(this.form_fields[0].value == '' || this.password == ''){
        this.feedback = 'Please enter all fields in this section'
        this.reminder = true
      }else{
        this.logging()
      }
    },
    logging(){
      this.processing = true
      fb.auth().signInWithEmailAndPassword(this.form_fields[0].value, this.password)
      .then((userCredential) => {
        // Signed in
        var user = userCredential.user;
        this.SET_USER_ID(user.uid)
        this.SET_TOKEN(user.refreshToken)
        setTimeout(()=>{ window.open('/', '_self') }, 3000);
        // ...
      })
      .catch((error) => {
        this.processing = false
        // var errorCode = error.code;
        console.log(error.code)
        if(error.code == 'auth/wrong-password'){
            this.feedback = 'Invalid email or password'
        }
      });
    },
  },
  computed:{
    ...mapState(['userID'])
  },
  created(){
    if(this.userID){
      window.open('/', '_self')
    }
  }
};
</script>
<style scroped>

.box {
  min-width: 400px;
  border: 2px solid #e7f2fd;
  box-shadow: 0 25px 50px -12px rgba(255, 255, 255, 0.25) !important;
}
.welcome-text{
  color: #526A92;
}
.submitWait {
  padding: 5px 10px;
  width: 20%;
  border-radius: 5px;
  margin: 0 20%;
  margin-top: 20px;
  font-size: 1.3rem;
  color: #fff;
  transition: 1s;
}
.submitWait:hover{
  opacity: .8;
}
.customeCard {
  width: 50%;
  display: block;
  margin: auto;
}
.waitListBtn {
  display: flex;
  margin: auto;
}
.callnow {
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(3.5px);
  -webkit-backdrop-filter: blur(3.5px);
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
.req-quote-group {
  width: 100%;
  height: 100%;
}
.quote-form {
  width: 50rem;
}
@media screen and (max-width: 900px) {

  form.quote-form.glassmorph.p-10.min-w-96.rounded-xl.shadow-lg.w-9\/12 {
    width: 100%;
  }
  /* .shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(13, 139, 139, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  } */
}
</style>
