<template>
<div id="InventoryMain">
  <h1 class="font-bold text-2xl text-left">Your Inventory</h1>

  <div class="my-5">
    <a href="/order" class="w-1/2 px-3 relative bg-green-400 text-white rounded mx-3 py-2">
      Order More
    </a>
  </div>

  <div class="inventory-grid">
    <div class="rounded-lg border-2 border-gray-300 text-center" v-for="(inv, idx) in inventory" :key="idx">
      <img :src="inv.uri" class="w-1/2 mx-auto"/>
      <p class="font-bold w-10/12 mx-auto text-lg">{{inv.name}}</p>
      <p class="text-sm">In stock: <span class="text-yellow-500">{{inv.stock}}</span></p>
      <p class="text-sm">Total sold: <span class="text-green-500">{{inv.sold}}</span></p>
      <button class="bg-green-500 text-blue-600 bg-blue-200 text-sm rounded py-1 px-2 my-2">Update Count</button>
    </div>
    <div class="flex flex-col items-cen border-2 border-blue-300 border-dashed rounded-lg text-center">
      <span class="plus-icon">+</span>
      <p class="w-8/12 mx-auto">
        Your inventory is empty, you can order from us here!
      </p>
      <div class="flex items-center justify-center">
  			<a href="/order" class="w-1/2 px-3 relative bg-green-400 text-white rounded mx-3 py-2">
  				Order Now
  			</a>
  		</div>
    </div>
  </div>

</div>
</template>
<script>
import inventory_list from '@/assets/json/inventory_list.json'
export default{
  name:'InventoryMain',
  components:{
  },
  data(){
    return{
      total_results: 0,
      qty: 0,
      copiedId: null,
      search_query: '',
      current_select:'Today',
      date_range: ['Today', 'This Week', 'This Month', 'This Year'],
      inventory: inventory_list
    }
  },
  methods:{
    // ...mapMutations(['UPDATE_SELECTED_USER']),
  },
  computed:{
    // ...mapState(['appdata']),
  },
  created(){
  }
}
</script>
<style scoped>
#InventoryMain{
  text-align: left
}
.inventory-grid{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-items: stretch;
  align-items: stretch;
}
.plus-icon{
  font-size:3rem;

  font-weight: bold;
  margin: 2rem auto;
}
</style>
