<template>
  <div  class="bg-white p-5 w-11/12 mx-auto rounded-3xl box"  v-if="!processing && !successAlert && userdata.status != 'expired' && userdata.status != 'not_found' ">
    <h1 class="text-4xl mb-2 font-bold">Account Setup</h1>
    <h2 class="welcome-text text-xl text-gray-500 font-bold">Now that you approved! <br />Finish setting up your account!</h2>

    <div class="bg-gray-50 border-2 border-dashed border-gray-400 block w-full text-left mx-auto rounded-3xl mb-5 mt-3 p-3">

      <p class="mb-5 font-bold">Welcome Back, {{userdata.businessname}}</p>

      <div class="block w-full text-left mx-auto rounded-3xl ">
        <p>Your email has been verfied</p>
        <div class="flex ml-3 my-2">
          <img class="w-5" src="@/assets/icons/history/check.svg" />
          <p class="ml-2 font-bold">{{userdata.email}}</p>
        </div>
        <a href="/register" class="text-red-400 text-sm">Not you? Sign up here</a>
      </div>
    </div>

        <p class="text-lg text-red-500">{{feedback}}</p>

      <div class="mb-4" >
        <label class="block text-left text-gray-500 text-sm font-bold mb-2"  for="password">New Password <span class="text-red-500 italic text-xs">required</span></label>
        <input
          class=" shadow appearance-none border rounded w-full py-2 px-3 text-gray-500 leading-tight focus:outline-none focus:shadow-outline" id="password" type="password" v-model="password" placeholder="Password"
        />
      </div>
      <div class="mb-4" >
        <label class="block text-left text-gray-500 text-sm font-bold mb-2"  for="password">Confirm Password <span class="text-red-500 italic text-xs">required</span></label>
        <input
          class=" shadow appearance-none border rounded w-full py-2 px-3 text-gray-500 leading-tight focus:outline-none focus:shadow-outline" id="password" type="password" v-model="confirm_password" placeholder="Confirm Password"
        />
      </div>

    <!-- <p>Already have an account? <a href="/login" class="text-theme font-bold">Login</a></p> -->

    <div class="flex justify-center items-center">
      <button v-if="regStep == 1" class="submitWait bg-black" @click.prevent="moveSlide(0)">
        Back
      </button>
      <button class="submitWait bg-theme" @click.prevent="validate()">
        <span >Continue</span>
      </button>
    </div>
  </div>
  <div class="bg-white p-12 w-11/12 md:w-8/12 lg:5/12 mx-auto rounded-3xl box" v-if="userdata.status == 'expired' ">
    Sorry, but this application has expired. In order to receieve a new link, please press the button and a new link would be sent to the email on file.

    <button class="submitWait w-1/2 bg-theme" @click.prevent="resendVerification()">
      <span >Resend Link</span>
    </button>
  </div>
  <div class="bg-white p-12 w-11/12 md:w-8/12 lg:5/12 mx-auto rounded-3xl box" v-if="userdata.status == 'not_found' ">
    <p class="text-black">{{feedback}}</p>
  </div>
  <div class=" bg-white p-12 w-11/12 md:w-8/12 lg:5/12 mx-auto rounded-3xl box" v-else-if="processing">
    <p class="italic">Please Wait...</p>
  </div>
  <div class=" bg-white p-12 w-11/12 md:w-8/12 lg:5/12 mx-auto rounded-3xl box text-center" v-else-if="!processing && successAlert">
    <h1 class="text-3xl lg:text-5xl my-10 font-bold mx-auto text-green-400">Your account set up is now complete!</h1>
    <p  class="text-lg lg:text-3xl my-5 text-gray-600">You should be directed to the main dashboard.</p>
    <p><a href="/" class="text-blue-400">Press here if you are not directed directly</a></p>
    <!-- <p  class="text-lg lg:text-3xl text-gray-600" >In the meantime feel free to follow us on social media!</p> -->
  </div>
</template>
<script>
import axios from 'axios'
import {fb} from '@/_firebase/init'
import {mapMutations} from 'vuex'

export default {
  name: "Application",
  components: {},
  data() {
    return {
      registration_token: this.$route.params.token,
      verifiedToken: false,
      processing: false,
      successAlert: false,
      status: '',
      reminder: false,
      regStep: 0,
      feedback: '',
      userdata:{
        businessname: '',
        email: '',
        applicationID: '',
        status: ''
      },
      password: '',
      confirm_password: '',
      bkgd_img: 'https://images.unsplash.com/photo-1504194782011-e78a3bf9d300?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
    };
  },
  methods: {
    ...mapMutations(['SET_USER_ID', 'SET_TOKEN']),
    moveSlide(x){
      this.regStep = x
    },
    checkAccountToken(){
      this.processing = true
      const config ={
        headers: {}
      }
      axios.get(`https://petsproject.skylineexecutive.com/wholesale/check_access_token/${this.registration_token}`, config
      ).then((res)=>{
        this.processing = false
        if(res.data.code == 'not_found'){
          this.userdata.status = res.data.code
          this.feedback = res.data.feedback
          this.$router.push({name: 'Register'})
        }else{
          this.userdata = res.data.result
        }
      }).catch((err)=>{
        this.processing = false
        this.feedback = 'Sorry, we could not complete your account. If the problem persists please contact support'
        console.log(err)
      })
    },
    resendVerification(){
      console.log('sent')
    },
    validate(){
      if(this.confirm_password.email == '' || this.password == '' ){
        this.feedback = 'Please enter all fields in this section'
        this.reminder = true
      }else if(this.password != this.confirm_password){
        this.feedback = 'Passwords do not match'
        this.reminder = true
      }else{
        this.register()
      }
    },
    completeUserAccount(uid, access_token) {
      this.processing = true
      const config ={
        headers: {}
      }
      const payload = {
        uid: uid,
        email: this.userdata.email,
        applicationID: this.userdata.applicationID
      }
      axios.post('https://petsproject.skylineexecutive.com/wholesale/register', payload, config
      ).then((res)=>{
        this.processing = false
        console.log(res.data)
        this.successAlert = true
        this.SET_USER_ID(res.data.result)
        this.SET_TOKEN(access_token)
        setTimeout(() =>{
          this.$router.push({name: 'Home'})
        }, 2000);

      }).catch((err)=>{
        this.processing = false
        this.feedback = 'Sorry, we could not complete your account. If the problem persists please contact support'
        console.log(err)
      })
    },
    register(){
        this.feedback = ""
      this.processing = true
      fb.auth().createUserWithEmailAndPassword(this.userdata.email, this.password)
        .then((userCredential) => {
          console.log('triggered')
          // Signed in
          var user = userCredential.user;
          console.log(user)
          this.completeUserAccount(user.uid, user.refreshToken)
        })
        .catch((error) => {
          this.feedback = error.message;
          this.processing = false
          console.log(error)
          // ..
        });
    },
  },
  created(){
    this.checkAccountToken()
  }
};
</script>
<style scroped>

.box {
  min-width: 400px;
  border: 2px solid #603784;
  box-shadow: 0 25px 50px -12px rgba(255, 255, 255, 0.25) !important;
}
.welcome-text{
  color: #526A92;
}
.submitWait {
  padding: 10px;
  min-width: 24%;
  border-radius: 10px;
  margin: 0 auto;
  color: #fff;
  transition: 1s;
}
.submitWait:hover{
  opacity: .8;
}
.customeCard {
  width: 50%;
  display: block;
  margin: auto;
}
.waitListBtn {
  display: flex;
  margin: auto;
}
.callnow {
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(3.5px);
  -webkit-backdrop-filter: blur(3.5px);
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
.req-quote-group {
  width: 100%;
  height: 100%;
}
.quote-form {
  width: 50rem;
}
@media screen and (max-width: 900px) {

  form.quote-form.glassmorph.p-10.min-w-96.rounded-xl.shadow-lg.w-9\/12 {
    width: 100%;
  }
  /* .shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(13, 139, 139, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  } */
}
</style>
