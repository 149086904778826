<template>
<div id="CraftAnOrder">
  <h1 class="text-2xl text-left font-bold">Order</h1>

  <div class="w-11/12 border-2 border-blue-100 rounded my-3 px-2 py-1">
    <ul class="flex">
      <li class="mr-3">- Products</li>
      <li class="mr-3">- Shipping</li>
      <li>- Review</li>
    </ul>
  </div>

  <section class="text-left w-11/12 mb-10">
    <h2 class="text-2xl"> Select Product and Quantity</h2>
		<button class="w-10/12 text-sm my-3 text-blue-400 flex items-center" @click="showPricingDetails(true)">
			View Pricing and Discount Details
			<svg xmlns="http://www.w3.org/2000/svg" fill="#68A8FA" id="Outline" class="ml-2" viewBox="0 0 24 24" width="12" height="12"><path d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm0,22A10,10,0,1,1,22,12,10.011,10.011,0,0,1,12,22Z"/><path d="M12,10H11a1,1,0,0,0,0,2h1v6a1,1,0,0,0,2,0V12A2,2,0,0,0,12,10Z"/><circle cx="12" cy="6.5" r="1.5"/></svg>
		</button>
		<PricingInfoModal v-if="displayPricingInfo" @showPricingDetails="showPricingDetails"/>
    <ProductDropdown :products="products" @selectedVal="selectedVal"/>
    <!-- <div v-for="(pdt, idx) in products" :key="idx" @click="selected_products.push(pdt)">
      {{pdt}}
    </div> -->
    <ProductTable :selected_products="selected_products"  @removeVal="removeVal"/>
  </section>

  <section class="text-left w-11/12 ">
    <h2 class="text-2xl">Select Address</h2>
		<AddressForm @setShipto="setShipto" :addresses="userdata.addresses" @updateAddress="updateAddress"/>
  </section>

  <section class="text-left w-11/12 ">
    <h2 class="text-2xl"> Select Payment Method and Review</h2>
		<Invoice :shipto_address="shipto_address" :selected_products="selected_products"/>
  </section>
</div>
</template>
<script setup>
import { ref, computed} from 'vue'
import axios from 'axios'
import { useStore } from 'vuex'
import ProductDropdown from './order/ProductDropdown.vue'
import ProductTable from './order/ProductTable.vue'
import AddressForm from '@/views/our-products/order/AddressForm.vue'
import Invoice from '@/views/our-products/order/Invoice.vue'
import products from '@/assets/json/products.json'
import PricingInfoModal from '@/views/our-products/order/modals/PricingInfoModal.vue'
import profile from '@/assets/json/profile.json'

const store = useStore()

const selected_products = ref([])
const shipto_address = ref({})
const displayPricingInfo = ref(false)
const userdata = ref(profile)

let userID = computed( () => {
	return store.state.userID
});

const selectedVal = (val) => {
	// let prods = []
	// prods.push(val)
	if(selected_products.value.indexOf(val) === -1) {
		val['qty'] = 0
    selected_products.value.push(val)
	}
}

const removeVal = (val) => {
	const index = selected_products.value.indexOf(val);
	if (index > -1) {
		selected_products.value.splice(index, 1); // 2nd parameter means remove one item only
	}
}
const showPricingDetails = (bool) =>{
	displayPricingInfo.value = bool
	// console.log(displayPricingInfo.value)
}
const setShipto = (address) => {
	shipto_address.value = address
}
const updateAddress = (addrs) => {
	const config = {
		headers: {
			userID: userID.value
		}
	}

	const payload = {
		'addresses': addrs
	}
	console.log(payload)
	axios.post('https://petsproject.skylineexecutive.com/wholesale/update_addresses', payload, config
	).then((res)=>{
		userdata.value.addresses = addrs
		console.log(res.data)
	}).catch((err)=>{
		console.log(err)
	})
}

const getUserdata = ()=> {
	const config = {
		headers: {
			userID: userID.value
		}
	}
	axios.get('https://petsproject.skylineexecutive.com/wholesale/one', config
	).then((res)=>{
		userdata.value = res.data.result
		console.log(userdata.value)
	}).catch((err)=>{
		console.log(err)
	})
}
getUserdata()
</script>
<style scoped>

</style>
