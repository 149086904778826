<template>
<div id="OrderStatus">
	<div class="flex justify-between">
		<h1 class="text-2xl text-left font-bold">Order Status: WS#000{{order_data.confirmation_number}}</h1>

		<button class="py-2 px-4 rounded-lg"
		:class="{
			'text-red-700 bg-red-300': order_status == 'not_paid',
			'text-yellow-700 bg-yellow-300': order_status == 'awaiting_fulfilment',
			'text-green-700 bg-green-300': order_status == 'order_completed'
			}"
		@click="changeStatus()">
			<span>{{order_data.status}}</span>
		</button>
	</div>

  <section class="text-left">
    <p>Hello Elongated Pets</p>
    <p>Your order has been submited, before we can confirm it, please pay to the one of the dedicated payment methods</p>
  </section>

	<!-- Payment -->
	<Payment2xOptions />

	<!-- <div>
		{{order_data}}
	</div> -->

  <!-- Address -->
  <section class="w-10/12 mx-auto text-left">

    <div class="flex justify-evenly">
      <div class="my-4">
        <p class="text-purple-300">Order Date</p>
        <p>{{order_data.created}}</p>
      </div>

      <div class="my-4">
        <p class="text-purple-300">Order Confirmation</p>
        <p>{{order_data.id}}</p>
      </div>

      <div class="my-4">
        <p class="text-purple-300">Delivery Address</p>
        <p>{{order_data.delivery_address.line1}}, {{order_data.delivery_address.line2}}</p>
        <p>{{order_data.delivery_address.city}},{{order_data.delivery_address.state}}, {{order_data.delivery_address.country}}</p>
				<p>{{order_data.delivery_address.zip}}</p>
      </div>

      <div class="my-4">
        <p class="text-purple-300">Payment</p>
        <p>Not yet recieved</p>
      </div>
    </div>
  </section>
	<hr class="w-10/12 mx-auto"/>
  <!-- devices -->
  <section class="w-10/12 mx-auto">

    <div class="relative ml-10" v-for="(item, idx) in order_data.products" :key="idx">
        <div class="flex items-center justify-between cursor-pointer text-left">
          <div class="flex items-center">
              <img class=" h-10 w-10" :src="item.uri" />
            <div class="text-sm font-medium text-gray-900 my-5">
              <p>{{ item.name }}</p>
              <p class="text-theme">qty. {{ item.qty }}</p>
            </div>
          </div>

          <div class="text-xl font-bold">
            ${{ (item.price_amount/100).toLocaleString() }}
          </div>
        </div>
    </div>

  </section>
	<hr class="w-10/12 mx-auto"/>
  <!-- total and prices -->
  <section class="w-10/12 mx-auto text-right">
    <ul class="text-right">
      <li class="my-2 text-lg">subtotal <span class="font-bold">included</span> </li>
      <li class="my-2 text-lg">taxes <span class="font-bold">included</span> </li>
      <li class="my-2 text-lg">shipping and handling <span class="font-bold">included</span> </li>
    </ul>
    <hr />
    <p class="text-xl">Total: <span class="text-2xl font-bold">${{(total/100).toLocaleString()}}</span></p>
  </section>

</div>
</template>
<script setup>
import { ref, computed } from 'vue'
import {useStore} from "vuex";
import { useRoute } from 'vue-router'
import axios from 'axios';
import Payment2xOptions from '@/views/our-products/order/Payment2xOptions.vue'
import order_history from '@/assets/json/order_history.json'

	const route = useRoute()
	const store = useStore()

	const order_id = route.params.order_id
	const order_data = ref(order_history[0])
	const order_status = ref('not_paid')

  let total = computed(() => {
		let amount = 0
		const all_products = order_data.value.products
		for(let od in all_products){
			amount = all_products[od].price_amount + amount
		}
		return amount
	})

	let userID = computed( () => {
		return store.state.userID
	});

	const getOrderData = () =>{
			const config ={
				headers: {
					userID: userID.value
				}
			}
			axios.get(`https://petsproject.skylineexecutive.com/wholesale/orders/${order_id}`, config
			).then((res)=>{
				// console.log(res.data)
				order_data.value = res.data.result
				// this.order_list = res.data.result
			}).catch((err)=>{
				console.log(err)
			})
		}

		const changeStatus = () => {
			if(this.order_status == 'not_paid'){
				this.order_status = 'awaiting_fulfilment'
			}else if(this.order_status == 'awaiting_fulfilment'){
				this.order_status = 'order_completed'
			}else{
				this.order_status = 'not_paid'
			}
		}
getOrderData()
</script>
<style scoped>
#OrderStatus{
	width: 80%;
	margin: auto;
}
</style>
