<template>
<div id="OurProductsList">
 <h1 class="text-2xl text-left font-bold">Our Products</h1>

  <div class="text-left flex justify-evenly text-gray-500 my-3">
    <p class="w-3/4">Pricing gets cheaper the more you order:
    BULK PRICING 30-99 ($15/unit)	/ 100-200	($13.50/unit)/ 201-300	($11.50/unit)/ 301-500	($11.25/unit)/ 501+ ($9.75/unit)
  </p>

    <a href="/order" class="text-center flex items-center w-44 justify-center px-3 relative bg-green-400 text-white rounded mx-3 py-2">
      Order Now
    </a>
  </div>

  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Retail Price
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Wholesale Price
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Quantity
                </th>
              </tr>
            </thead>

            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="(user, idx) in products" :key="idx">
                <td class="px-6 py-4 whitespace-nowrap">

                  <div class="flex items-center cursor-pointer" @click="copyToClipboard(user.id)">
                    <div class="flex-shrink-0 h-10 w-10">
                      <img :src="user.uri" />
                    </div>
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900">
                        {{ user.name }}
                      </div>
                    </div>
                  </div>

                </td>
                <td class="px-6 py-4">
                  $27.95
                </td>
                <td class="dates-created px-6 py-4 whitespace-nowrap text-sm text-gray-500 cursor-pointer relative">
                    $15.99
                    <span class="text-xs text-blue-400">- Bitcoin Price</span>
                </td>
                <td class="px-6 py-4 text-center">
                  <input v-model="qty" class="py-2 border rounded-lg px-3"/>
                </td>
              </tr>
            </tbody>
          </table>
          <PaginationBar
            @paginate="paginate"
            :total_results="total_results"
            :count_start="1"
            :count_end="products.length"
            />
        </div>
      </div>
    </div>
  </div>

</div>
</template>
<script>
// import {mapState, mapMutations} from 'vuex'
import PaginationBar from './PaginationBar.vue'
import products from '@/assets/json/products.json'

export default{
  name:'OurProductsList',
  props:['users_list'],
  components:{
    PaginationBar
  },
  data(){
    return{
      total_results: 0,
      qty: 0,
      copiedId: null,
      search_query: '',
      current_select:'Today',
      date_range: ['Today', 'This Week', 'This Month', 'This Year'],
      products: products
    }
  },
  methods:{
    paginate(page){
      this.$emit('paginate', page)

    },
    gotoHref(link){
      window.open('https://barkbadge.web.app/tag/'+link)
    },
    copyToClipboard(user_id){
      const link = user_id
      navigator.clipboard.writeText(link);
      this.copiedId = user_id
      setTimeout(() =>{
        this.copiedId = null
      }, 1000);
    },
    // ...mapMutations(['UPDATE_SELECTED_USER']),
  },
  computed:{
    // ...mapState(['appdata']),
  },
  created(){
  }
}
</script>
<style scoped>
.icon{
  width: 1rem;
}
.tag-icon{
  width: 2rem;
  margin: 0 .5rem;
}
.dates-created:hover .simp-date{
  position: absolute;
  transform: translateY(-12px);
  transition: 1s;
}
.dates-created:hover .fmt-date{
  display: block;
  position: absolute;
  transform: translateY(4px);
  transition: 1s;
}

/* DEPRECATED */
</style>
