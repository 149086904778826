import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/auth'
// Your web app's Firebase configuration

var firebaseConfig = {
  apiKey: "AIzaSyDmngI1xizCHr3uwL5lwg13RzCbYKxP2sM",
  authDomain: "barkbadge-suites.firebaseapp.com",
  projectId: "barkbadge-suites",
  storageBucket: "barkbadge-suites.appspot.com",
  messagingSenderId: "816136171422",
  appId: "1:816136171422:web:38bc9b915d6130e928f08f",
  measurementId: "G-32MYYYJ14S"
};
// Initialize Firebase
const fb = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
const fs = firebase.firestore

export { fb, db, fs }
