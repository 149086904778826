<template>
<section class="w-11/12 mx-auto text-left">
  <div class="mx-auto flex items-start justify-evenly my-5">

    <div class="text-xl border border-gray-300 rounded py-2 px-4 cursor-pointer" @click="payment.method = 'bitcoin'">
      <div class="flex items-center">
        <img class="w-10 h-10 mr-4" src="@/assets/icons/order-invoice/bitcoin.svg" />
        Bitcoin
      </div>
      <div v-if="payment.method == 'bitcoin'">
        <p class="text-sm">Bitcoin Address: 1LMPN6RybwBAi8irCxJMQAJACBYACP4kdk</p>
        <div class="flex flex-col text-sm mt-3">
            <p>Transaction Hash:</p>
            <small class="explorer-links">https://blockchain.coinmarketcap.com/ , https://live.blockcypher.com/btc/, https://www.blockchain.com/explorer</small>
          <input placeholder="https://blockchain.coinmarketcap.com/tx/bitcoin/..." class="border border-gray-300 rounded" type="text"/>
        </div>
        <p class="text-xs text-green-700 my-4" v-if="feedback != ''">{{feedback}}</p>
        <button class="bg-green-400 text-white py-1 px-3 text-sm rounded" @click="updatePaymentStatus('proof', 'payment_submitted')">Sent</button>
      </div>
    </div>


    <div class="text-xl border border-gray-300 rounded py-2 px-4 cursor-pointer"  @click="payment.method = 'wire'">
      <div class="flex items-center">
        <img class="w-10 h-10 mr-4" src="@/assets/icons/order-invoice/wire-transfer.svg" />
        Wire Transfer
      </div>
      <div class="text-sm mt-3" v-if="payment.method == 'wire'">
        <p >Corporation: BARK BADGE LLC.</p>
        <p>Address: 2697 Old Peachtree Rd NW, Duluth, GA 30097</p>
        <p>Account: 761391298</p>
        <p>Routing: 061092387</p>
        <p>Swift: CHASUS33</p>
        <div class="flex flex-col mt-3">
          <p class="text-base">Screenshot:</p>
          <small>Submit screenshot for faster approval</small>
          <input class="border border-gray-300 rounded" type="file"/>
        </div>
        <p class="text-xs text-green-700 my-4" v-if="feedback != ''">{{feedback}}</p>
        <button class="bg-green-400 text-white py-1 px-3 text-sm rounded" @click="updatePaymentStatus('proof', 'payment_submitted')">Sent</button>
      </div>
    </div>
  </div>

</section>
</template>
<script>
export default{
  data(){
    return{
      payment: {
				method: '',
				status: '',
				proof: ''
			},
			feedback: '',
			businessname: 'My Good Pet Store'
    }
  },
  methods:{
    updatePaymentStatus(proof, status){
      this.feedback = 'Thank you! We will look out for your payment, for faster order confirmation, please contact your Bark Badge WS Representative.'
      this.payment.status = status
      this.payment.proof = proof
    }
  }
}
</script>
