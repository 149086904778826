<template>
<div class="modal">
  <div class="modal-overlay" @click="showPricingDetails()"></div>
  <div class="modal-body">
    <h2 class="text-2xl font-bold">Discount Pricing</h2>
    <p class="italic text-gray-300">Pricing gets cheaper the more you order:</p>
    <hr />
    <ul>
      <li class="my-2"> 30-99 units => $15/unit</li>
      <li class="my-2">100-200 units => $13.50/unit</li>
      <li class="my-2">201-300 units => $11.50/unit</li>
      <li class="my-2">301-500 units => $11.25/unit</li>
      <li class="my-2">501+ units => $9.75/unit</li>
      <li class="my-2">5,001+ units => contact us</li>
    </ul>

    <button class="bg-blue-500 text-white font-bold rounded-lg py-1 px-3 mx-auto w-1/12"  @click="showPricingDetails()"> Ok</button>
  </div>
</div>
</template>
<script>
export default{
  name: 'PricingInfoModal',
  methods:{
    showPricingDetails(){
      this.$emit('showPricingDetails', false)
    }
  }
}
</script>
<style scoped>
.modal{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}
.modal-overlay{
  background: #000;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: .5;
  z-index: 51;
}
.modal-body{
  background: #fff;
  border-radius: 10px;
  padding: 2rem 3rem;
  position: absolute;
  left: 50%;
  top: 25%;
  transform: translate(-50%,-25%);
  width: 50%;
  z-index: 52;
}
</style>
