<template>
    <!-- saved addresses -->
    <section>
      <ul role="list" class="border border-gray-200 rounded-md divide-y divide-gray-200" v-if="addresses.length">

        <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm bg-white"
          v-for="(svdl, idx) in addresses" :key="idx"
          :class="{'border-blue-400 border-2': svdl.id == ship_to.id}">

          <button class="rounded-full border border-ray-300 w-4 h-4"
            :class="{'bg-blue-400 ': svdl.id == ship_to.id}"
            @click="selectShiptoAddress(svdl)"></button>

          <div class="w-0 flex-1 flex flex-col items-start">
            <p class="mx-2 cursor-pointer font-bold" @click="selectShiptoAddress(svdl)">{{svdl.location_name}}</p>
            <p class="mx-2 text-xs text-gray-500">{{svdl.line1}} {{svdl.line2}}, {{svdl.city}}, {{svdl.state}}</p>
            <p class="mx-2 text-xs text-gray-500">{{svdl.country}} {{svdl.zip}}</p>
          </div>
          <button class="text-blue-400 font-bold" @click="toggleEditAddress(svdl)">Edit</button>
          <!-- <button class="text-red-400 font-bold ml-4" @click="removeAddress(svdl.id)">Delete</button> -->
        </li>

      </ul>
      <div v-else>
        <p class="text-gray-700 text-sm mt-5 mb-3">You do not have any saved addresses.</p>
        <p class="text-gray-700 text-sm my-3">You can add a new one below</p>
      </div>

    </section>

    <!-- new address -->
    <section >
      <button class="flex my-2 items-center justify-between rounded-lg shadow py-2 px-3 bg-blue-400 text-white mx-auto"
            @click.prevent="toggleEditAddress('new')">
          <p class="mx-2 font-bold">Add New Address</p>
      </button>

      <!-- new address dropdown -->
      <div class="block w-full text-left mx-auto rounded-3xl " v-if="editAddress">
          <div class="mb-4" v-for="(field,idx) in form_fields" :key="idx">
            <label class="block text-gray-500 text-sm font-bold mb-2" for="username">
              {{field.label}} <span class="text-red-200" ></span>
            </label>
            <input
              class=" shadow appearance-none border rounded w-full py-2 px-3 text-gray-500 leading-tight focus:outline-none focus:shadow-outline"  :type="field.input_type"
              :class="{'border-red-500': reminder}"
              v-model="field.value" :placeholder="field.placeholder"
            />
          </div>
          <button class="flex my-2 mx-auto items-center justify-between rounded-lg shadow py-2 px-3 bg-blue-400 text-white"
                @click.prevent="newAddress()">
              <p class="mx-2 font-bold">Save Address</p>
          </button>
      </div>
    </section>
</template>
<script>
// import axios from 'axios'
// import {fb} from '@/_firebase/init'

export default {
  name: "AddressesForm",
  components: {},
  props:['addresses'],
  data() {
    return {
      editAddress: false,
      processing: false,
      successAlert: false,
      status: '',
      reminder: false,
      regStep: 0,
      feedback: '',
      ship_to: {},
      saved_locations:[
        {
          id: '23-9jewf23',
          created: 1653359856,
          name: 'My Address 1',
          line1:'7143 Gates Court',
          line2: '',
          city: 'Lititz',
          state: 'PA',
          country: 'United States',
          zip: '17543'
        },
      ],
      form_fields: [
        {label: 'Location Name', placeholder:'Office Building A, Shop B', value:'', input_type:'text', isRequired:false},
        {label: 'Steet Name', placeholder:'123 Main Street.', value:'', input_type:'text', isRequired:true},
        {label: 'Apt/Unit #/Ste', placeholder:'1', value:'', input_type:'text', isRequired:false},
        {label: 'City', placeholder:'Anytown', value:'', input_type:'text', isRequired:true},
        {label: 'State/Region/Province', placeholder:'State', value:'', input_type:'text', isRequired:false},
        {label: 'Country', placeholder:'United States', value:'', input_type:'text', isRequired:true},
        {label: 'Postal Code / Zip', placeholder:'00100', value:'', input_type:'text', isRequired:true},
      ],
    };
  },
  methods: {
    toggleEditAddress(shipto){
      if(shipto == 'new'){
        this.editAddress = !this.editAddress
      }else{
        console.log(shipto)
      }
    },
    selectShiptoAddress(shipto){
      this.ship_to = shipto
      this.$emit('setShipto', shipto)
    },
    guidGenerator() {
      let S4 = (((1+Math.random())*0x10000)|0).toString(16).substring(1);
      return S4
    },
    newAddress(){
      const epoch = Math.floor(Date.now() / 1000)
      let id1 = this.guidGenerator()
      let id2 = this.guidGenerator()
      let id3 = this.guidGenerator()
      let id4 = this.guidGenerator()
      let new_addr = {
                id: `${id1}${id2}-${id3}${id4}`,
                created: epoch,
                location_name: this.form_fields[0].value,
                line1: this.form_fields[1].value,
                line2: this.form_fields[2].value,
                city: this.form_fields[3].value,
                state: this.form_fields[4].value,
                country: this.form_fields[5].value,
                zip: this.form_fields[6].value,
              }

      let addrs = this.addresses
      addrs.push(new_addr)
      console.log(addrs)
      this.$emit('updateAddress', addrs)
      this.editAddress =  false
    },
  },
};
</script>
<style scroped>

@media screen and (max-width: 900px) {
}
</style>
