<template>
  <div as="div" class="relative inline-block text-left">
    <div>
      <button class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 " @click="toggleDropdown()">
        Select A Product
      </button>
    </div>


      <div class="dropdown-menu origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"  v-if="showDropdown" >
        <div class="dpdn-item py-1 flex items-center cursor-pointer" v-for="(pdt, idx) in products" :key="idx" @click="selectedVal(pdt)">
          <img :src="pdt.uri" class="w-14 rounded-full"/>
          <p>{{pdt.name}}</p>
        </div>
      </div>


  </div>
</template>

<script>

export default {
  props: ['products'],
  data(){
    return{
      showDropdown: false
    }
  },
  methods:{
    toggleDropdown(){
      this.showDropdown = !this.showDropdown
    },
    selectedVal(item){
      this.showDropdown = false
      this.$emit('selectedVal', item)
    }
  }
}
</script>
<style scoped>
.dropdown-menu{
  z-index: 20;
}
.dpdn-item:hover{
  background-color: #f5f5f5;
}
</style>
