<template>
  <div class="bg-white rounded-lg">
    <h1 class="font-bold text-2xl text-left">Account</h1>

    <div class="bg-white shadow overflow-hidden sm:rounded-lg w-11/12 mx-auto mt-10">

    <div class="account-info">

      <div class="profile-image-group">
        <div class="profile-image" :style="{'background-image': 'url(' + userdata.photo + ')'}">
        </div>
      </div>

        <div class="text-left">
          <!-- <div class="person-info pl-1">
            <h3 class="dark-text">Account ID: {{userID}}</h3>
          </div> -->

          <div class="person-info pl-1">
            <h3 class="dark-text">Company Information</h3>
            <p>{{userdata.businessname}}</p>
            <p>{{userdata.email}}</p>
            <p>{{userdata.phone}}</p>
            <!-- <small class="dark-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</small> -->
          </div>

          <!-- <div>
            {{userdata}}
          </div> -->

          <br />

        </div>
    </div>


    <section class="border-t border-gray-200">
      <dl>
        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Business Name</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{userdata.businessname}}</dd>
        </div>
        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Product/Service</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{userdata.product_service}}</dd>
        </div>
        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Email address</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{userdata.email}}</dd>
        </div>
        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Expected Volume</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{userdata.customer_volume}}</dd>
        </div>

        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Delivery Addresses</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <AccountAddresses :addresses="userdata.addresses" @updateAddress="updateAddress"/>
          </dd>
        </div>

        <!-- <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Legal Documents</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <ul role="list" class="border border-gray-200 rounded-md divide-y divide-gray-200">
              <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                <div class="w-0 flex-1 flex items-center">

                  <span class="ml-2 flex-1 w-0 truncate"> joint_marketing_terms.pdf </span>
                </div>
                <div class="ml-4 flex-shrink-0">
                  <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">  Read Now  </a>
                </div>
              </li>
              <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                <div class="w-0 flex-1 flex items-center">

                  <span class="ml-2 flex-1 w-0 truncate"> product_licensing_agreements.pdf </span>
                </div>
                <div class="ml-4 flex-shrink-0">
                  <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500"> Read Now </a>
                </div>
              </li>
            </ul>
          </dd>
        </div> -->
      </dl>
    </section>

  </div>

    <!-- <div class="text-center flex ">
        <div
          v-for="(info, index) in details"
          :key="index"
          :class="getClasses(index)"  >
          <h5  class="dark-text">
            {{ info.title }}
            <br />
            <small  class="dark-text">{{ info.subTitle }}</small>
          </h5>
        </div>
    </div> -->

  </div>
</template>
<script>
import axios from 'axios'
import profile from '@/assets/json/profile.json'
import {mapState} from 'vuex'
import AccountAddresses from '@/views/account/components/AccountAddresses.vue'

export default {
  components:{
    AccountAddresses
  },
  data() {
    return {
      userdata: profile,
      banner: 'https://images.unsplash.com/photo-1506905925346-21bda4d32df4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
      details: [
        {
          title: "1,234",
          subTitle: "Loads Completed",
        },
        {
          title: "124",
          subTitle: "Drivers",
        },
        {
          title: "$7.32 Million",
          subTitle: "Revenue Generated",
        },
      ],
    };
  },
  methods: {
    updateAddress(addrs){
      const config = {
        headers: {
          userID: this.userID
        }
      }

      const payload = {
        'addresses': addrs
      }
      console.log(payload)
      axios.post('https://petsproject.skylineexecutive.com/wholesale/update_addresses', payload, config
      ).then((res)=>{
        this.userdata.addresses = addrs
        console.log(res.data)
      }).catch((err)=>{
        console.log(err)
      })
    },
    getClasses(index) {
      var remainder = index % 3;
      if (remainder === 0) {
        return "col-lg-3 offset-lg-1";
      } else if (remainder === 2) {
        return "col-lg-4";
      } else {
        return "col-lg-3";
      }
    },
    getUserdata(){
      const config = {
        headers: {
          userID: this.userID
        }
      }
      axios.get('https://petsproject.skylineexecutive.com/wholesale/one', config
      ).then((res)=>{
        this.userdata = res.data.result
        console.log(this.userdata)
      }).catch((err)=>{
        console.log(err)
      })
    }
  },
  computed:{
    ...mapState(['userID'])
  },
  created(){
    this.getUserdata()
  }
};
</script>
<style scoped>
  h1, h2, h3, h4, h5, p, small, label{
   color: rgb(0, 0, 0) !important;
   transition: background 0.3s ease-in-out;
 }
.dark .dark-text{
  color: rgb(255, 255, 255) !important;
  transition: background 0.3s ease-in-out;
}
.dark input {
  color: #000;
  border-radius: 5px;
  padding: 5px;
  transition: background 0.3s ease-in-out;
}
.dark section{
  background-color: black !important;
  transition: background 0.3s ease-in-out;
}
.account-info{
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-items: stretch;
  align-items: stretch;
}
.profile-data{
  top: 1rem;
  position: absolute;
  z-index: 2;
  left:25%;
  width: 50%;
  right: 25%;
  transition: 1s;
}
.profile-image-group{
  position:relative;
  width: 50%;
  margin: auto;
}
.profile-image{
  position:relative;
  width: 8rem;
  height: 8rem;
  margin: auto;
  border-radius: 100%;
  overflow:hidden;
  border: 5px solid var(--bg-default);
  /* Create the parallax scrolling effect */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
}
</style>
