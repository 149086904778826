<template>
<div id="IntroductionSection" class="text-left">
	<h1 class="text-3xl font-bold">Welcome to the Bark Badge Wholesale Center!</h1>

	<div class="intro-grid border border-gray-300 rounded-lg p-2 my-4">
		<img class="intro-img rounded-lg" src="https://cdn.shopify.com/s/files/1/0479/3905/9862/files/dog-tags-for-puppy_600x.jpg?v=1640201932">
		<div>
			<h2 class="text-xl my-2 font-bold">About Our Badges</h2>
			<p class="my-3"> Our badges just keep getting smarter! Every month we release NEW FREE updates to our app.</p>
			<p class="my-3">Bark Badge is the SMARTEST dog tag in the world that does not require a subscription or battery to work. Simply scan or tap your badge to access all of your pets information</p>
			<p class="my-3">You can display a gallery or pictures with tons of contact information, owner information, vet records and more.</p>
		</div>
	</div>

</div>
</template>
<script>
export default{
	name: 'IntroductionSection',
  data(){
    return{
			order_status: 'not_paid',
			order_status_text: 'Unconfirmed',
      total: 999900,
    }
  },
	methods:{
		changeStatus(){
			if(this.order_status == 'not_paid'){
				this.order_status = 'awaiting_fulfilment'
				this.order_status_text = 'Awaiting Fulfilment'
			}else if(this.order_status == 'awaiting_fulfilment'){
				this.order_status_text = 'Order Shipped'
				this.order_status = 'order_completed'
			}else{
				this.order_status_text = 'Unconfirmed'
				this.order_status = 'not_paid'
			}

		}
	}
}
</script>
<script setup>
// import { ref } from 'vue'
// import ProductDropdown from './order/ProductDropdown.vue'
// import ProductTable from './order/ProductTable.vue'
// import AddressForm from '@/views/our-products/order/AddressForm.vue'
// import Invoice from '@/views/our-products/order/Invoice.vue'
// import products from '@/assets/json/products.json'
//
// const selected_products = ref([])
//
// const selectedVal = (val) => {
// 	// let prods = []
// 	// prods.push(val)
// 	if(selected_products.value.indexOf(val) === -1) {
// 		val['qty'] = 0
//     selected_products.value.push(val)
// 	}
// }
//
// const removeVal = (val) => {
// 	const index = selected_products.value.indexOf(val);
// 	if (index > -1) {
// 		selected_products.value.splice(index, 1); // 2nd parameter means remove one item only
// 	}
// }

</script>
<style scoped>
#IntroductionSection{

}
.intro-grid{
	display: grid;
	grid-template-columns: 1fr 2fr;
	grid-template-rows: 1fr;
	grid-column-gap: 20px;
	grid-row-gap: 20px;
	justify-items: stretch;
	align-items: stretch;
}
.intro-img{
	min-width: 200px;
	min-height: 200px;
	max-width: 300px;
	max-height: 300px;
}
</style>
