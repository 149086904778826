<template>
  <div id="Invoice">
    <!-- Address -->
    <section class="w-10/12 mx-auto text-left">
      <div class="flex items-center border-b-2 border-gray-300 border-dashed py-2">
        <img class="w-8 mr-3" src="@/assets/icons/order-invoice/shipping.svg" />
        <p class="text-xl">Shipping</p>
      </div>

      <div class="flex flex-col ml-10" v-if="shipto_address['location_name'] != undefined">
        <div class="my-4">
          <p class="text-purple-300">Customer Name</p>
          <p>{{businessname}}</p>
        </div>
        <div class="my-4" >
          <p class="text-purple-300">Delivery Address</p>
					<p class="font-bold">{{shipto_address['name']}}</p>
          <p>{{shipto_address['line1']}}, {{shipto_address['line2']}}</p>
          <p>{{shipto_address['city']}}, {{shipto_address['state']}} {{shipto_address['country']}}</p>
					<p>{{shipto_address['zip']}}</p>
        </div>

        <div class="my-4">
          <p class="text-purple-300">Expected Delivery Date</p>
          <p>10-12 Business Days</p>
        </div>
      </div>
			<div class="text-left" v-else>
				<p class="italic text-gray-500">Please select an address</p>
			</div>
    </section>
    <!-- devices -->
    <section class="w-10/12 mx-auto">
      <div class="flex items-center border-b-2 border-gray-300 border-dashed py-2">
        <img class="w-8 mr-3" src="@/assets/icons/order-invoice/package.svg" />
        <p class="text-xl">Details</p>
      </div>
			<div v-if="selected_products.length > 0">
				<div class="relative ml-10" v-for="(item, idx) in selected_products" :key="idx">
					<div class="flex items-center justify-between cursor-pointer text-left">
						<div class="flex items-center">
							<img class=" h-10 w-10" :src="item.uri" />
							<div class="text-sm font-medium text-gray-900 my-5">
							<p>{{ item.name }}</p>
							<p class="text-theme">qty. {{ item.qty }}</p>
							</div>
						</div>

						<div class="text-xl">
							${{ (item.qty * 11.25).toLocaleString() }}
						</div>
					</div>
				</div>
			</div>
			<div class="text-left" v-else>
				<p class="italic text-gray-500">Your Cart is Empty</p>
			</div>

    </section>
		<!-- Payment -->
		<section class="w-10/12 mx-auto text-left">
			<div class="flex items-center border-b-2 border-gray-300 border-dashed py-2">
				<img class="w-8 mr-3" src="@/assets/icons/order-invoice/money.svg" />
				<p class="text-xl"> Payment</p>
			</div>
			<p class="italic text-gray-500">Please select your prefered payment method</p>

			<!-- <div class="w-7/12 mx-auto flex items-center justify-between my-5">
				<button class="text-xl border border-gray-300 rounded py-2 px-4 flex items-center">
					<img class="w-10 h-10 mr-4" src="@/assets/icons/order-invoice/bitcoin.svg" />
					Bitcoin
				</button>
				<button class="text-xl border border-gray-300 rounded py-2 px-4 flex items-center">
					<img class="w-10 h-10 mr-4" src="@/assets/icons/order-invoice/wire-transfer.svg" />
					Wire Transfer
				</button>
			</div> -->

			<div class="mx-auto flex items-start justify-evenly my-5">

				<div class="text-xl border border-gray-300 rounded py-2 px-4 cursor-pointer" @click="payment.method = 'bitcoin'">
					<div class="flex items-center">
						<img class="w-10 h-10 mr-4" src="@/assets/icons/order-invoice/bitcoin.svg" />
						Bitcoin
					</div>
					<div v-if="payment.method == 'bitcoin'">
						<p class="text-sm">Bitcoin Address: 1LMPN6RybwBAi8irCxJMQAJACBYACP4kdk</p>
						<div class="flex flex-col text-sm mt-3">
								<p>Transaction Hash:</p>
								<small class="explorer-links">https://blockchain.coinmarketcap.com/ , https://live.blockcypher.com/btc/, https://www.blockchain.com/explorer</small>
							<input placeholder="https://blockchain.coinmarketcap.com/tx/bitcoin/..." class="border border-gray-300 rounded" type="text"/>
						</div>
						<p class="text-xs text-green-700 my-4" v-if="feedback != ''">{{feedback}}</p>
						<button class="bg-green-400 text-white py-1 px-3 text-sm rounded" @click="updatePaymentStatus('proof', 'payment_submitted')">Sent</button>
					</div>
				</div>


				<div class="text-xl border border-gray-300 rounded py-2 px-4 cursor-pointer"  @click="payment.method = 'wire'">
					<div class="flex items-center">
						<img class="w-10 h-10 mr-4" src="@/assets/icons/order-invoice/wire-transfer.svg" />
						Wire Transfer
					</div>
					<div class="text-sm mt-3" v-if="payment.method == 'wire'">
						<p >Bank: CHASE</p>
						<p >Corporation: BARK BADGE LLC.</p>
						<p>Address: 2697 Old Peachtree Rd NW, Duluth, GA 30097</p>
						<p>Account: 761391298</p>
						<p>Routing: 061092387</p>
						<p>Swift: CHASUS33</p>
						<div class="flex flex-col mt-3">
							<p class="text-base">Screenshot:</p>
							<small>Submit screenshot for faster approval</small>
							<input type="file"
				          accept="image/jpeg, image/png, image/gif"
				          @change="uploadGallery"
				          multiple="multiple"
									class="border border-gray-300 rounded"
									/>
						</div>
						<p class="text-xs text-green-700 my-4" v-if="feedback != ''">{{feedback}}</p>
						<button class="bg-green-400 text-white py-1 px-3 text-sm rounded" @click="updatePaymentStatus('proof', 'payment_submitted')">Sent</button>
					</div>
				</div>
			</div>

		</section>
    <!-- total and prices -->
    <section class="w-10/12 mx-auto text-right">
      <div class="flex items-center border-b-2 border-gray-300 border-dashed py-2">
        <img class="w-8 mr-3" src="@/assets/icons/order-invoice/shipping.svg" />
        <p class="text-xl">Summary</p>
      </div>

      <ul class="text-right">
        <li class="my-2 text-xl">subtotal <span>$0</span> </li>
        <!-- <li class="my-2 text-xl">taxes <span>$0</span> </li> -->
        <li class="my-2 text-xl">shipping and handling <span>$0</span> </li>
      </ul>
      <hr />
      <p class="text-xl">Total: <span class="text-2xl font-bold">${{(calcTotal/100).toLocaleString()}}</span></p>
    </section>


		<button class="w-1/2 md:w-4/12 mx-auto py-2 px-4 text-lg rounded text-white bg-green-600" @click="sendOrderRequest()">Submit Order Request</button>
  </div>
</template>
<script>
import {fb} from '@/_firebase/init'
import {mapState} from 'vuex'
import axios from 'axios'
export default{
	props:['shipto_address', 'selected_products'],
  data(){
    return{
			payment: {
				method: '',
				status: '',
				proof: ''
			},
			newImgs: [],
			feedback: '',
			businessname: 'My Good Pet Store'
    }
  },
	methods:{
		uploadGallery(evt){
	this.processing = true
	if(evt.target.files.length > 5){
		this.feedback = 'Only up to 5 files'
		// this.ALERT_CODE('max_file_upload', evt.target.files.length)
		this.processing = false
	}else{
		for(let file of evt.target.files){
			console.log(file)
			var storeRef = fb.storage().ref('/order_confirmations/'+file.name);
			let uploadTask = storeRef.put(file);
			uploadTask.on('state_changed', () =>{
			},(err) => {
				console.log(err);
			},() => {
				uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
					this.newImgs.push(downloadURL);
					this.payment.proof = this.newImgs
				});
			})
			// if(file.size > 2000000){
			// 	this.processing = false
			// 	break;
			// }else{
			// 	var storeRef = fb.storage().ref('/pet_profile_media/pet_gallery/'+file.name);
			// 	let uploadTask = storeRef.put(file);
			// 	uploadTask.on('state_changed', () =>{
			// 	},(err) => {
			// 		console.log(err);
			// 	},() => {
			// 		uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
			// 			this.newImgs.push(downloadURL);
			// 		});
			// 	})
			// }
		}
		this.processing = false
	}
},
		sendOrderRequest(){
			let cart = this.selected_products
			for(let i in cart){
				cart[i]['price_amount'] = this.calcWholesalePrice(cart[i].qty)
			}
			const config ={
				headers: {
					userID: this.userID
				}
			}
			const payload = {
				status: this.payment.status,
				products: cart,
				total_amount: this.calcTotal,
				delivery_address:this.shipto_address,
				payment_method:{
						source: this.payment.method,
						proof: this.payment.proof
				},
				"name": this.businessname,
				"userID": this.userID
		}
			axios.post('https://petsproject.skylineexecutive.com/wholesale/orders/create', payload, config).then((res)=>{
				const o_id = res.data.result.id
				window.open(`/order/${o_id}`, '_self')
			}).catch((err)=>{
				console.log(err)
			})
		},
		calcWholesalePrice(qty){
			let total_val = 0;
			if(qty >= 30 && qty < 100){
				total_val = qty * 1500
			}else if(qty >= 100 && qty < 200){
				total_val = qty * 1350
			}else if(qty >= 200 && qty < 300){
				total_val = qty * 1150
			}else if(qty >= 300 && qty < 500){
				total_val = qty * 1100
			}else if(qty >= 500){
				total_val = qty * 975
			}
			return total_val
		},
		updatePaymentStatus(proof, status){
			this.feedback = 'Thank you! We will look out for your payment, for faster order confirmation, please contact your Bark Badge WS Representative.'
			this.payment.status = status
			this.payment.proof = proof
		}
	},
	computed:{
		...mapState(['userID']),
		calcTotal(){
			let total = 0;
			for(let sp in this.selected_products){
				let item = this.selected_products[sp]
				total =  Number(total) + Number(this.calcWholesalePrice(item.qty))
			}
			return total
		}
	}
}
</script>
<style scoped>
#Invoice{
	text-align: center;
}
.explorer-links{
	font-size: .5rem;
}
</style>
