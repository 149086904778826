<template>
<div class="ProductTable">
  <div class="flex flex-col my-2">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Wholesale Price
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Total Price
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Quantity
                </th>
                <th scope="col" class="px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">

                </th>
              </tr>
            </thead>

            <tbody class="bg-white divide-y divide-gray-200" v-if="selected_products.length > 0">
              <tr class="product-row relative" v-for="(pdt, idx) in selected_products" :key="idx">
                <td class="px-6 py-2 whitespace-nowrap">

                  <div class="flex items-center cursor-pointer">
                    <div class="flex-shrink-0 h-10 w-10">
                      <img :src="pdt.uri" />
                    </div>
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900">
                        {{ pdt.name }}
                      </div>
                    </div>
                  </div>

                </td>
                <td class="dates-created px-6 py-2 whitespace-nowrap text-sm text-gray-500 cursor-pointer relative">
                    <span >${{(calcWholesalePrice(pdt.qty) / 100).toLocaleString()}}/unit</span>
                </td>
                <td class="dates-created px-6 py-2 whitespace-nowrap text-sm text-gray-500 cursor-pointer relative">
                    <span >${{(calcTotalPrice(pdt.qty) / 100).toLocaleString()}}</span>
                </td>
                <td class="px-6 py-2 text-center">
                  <input v-model="pdt.qty" type="number" class="py-1 border rounded-lg px-3"/>
                  <p class="text-xs text-red-400" v-if="pdt.qty > 0 && pdt.qty <= 29">The minimum order is 30 units</p>
                </td>
                <button class="close-btn" @click="removeVal(pdt)">&times;</button>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td  colspan="4" class="text-center w-full py-3 text-gray-400">Your cart is empty, please select your products</td>
              </tr>
            </tbody>
          </table>
          <PaginationBar
            @paginate="paginate"
            :total_results="selected_products.length"
            :count_start="1"
            :count_end="selected_products.length"
            />
        </div>
      </div>
    </div>
  </div>

</div>
</template>
<script>
// import {mapState, mapMutations} from 'vuex'
import PaginationBar from '@/views/our-products/PaginationBar.vue'
import products from '@/assets/json/products.json'

export default{
  name:'ProductTable',
  components:{
    PaginationBar
  },
  props:['selected_products'],
  data(){
    return{
      total_results: 0,
      copiedId: null,
      products: products
    }
  },
  methods:{
    paginate(page){
      this.$emit('paginate', page)
    },
    removeVal(val){
      this.$emit('removeVal', val)
    },
    calcTotalPrice(qty){
      let total_val = 0;
      if(qty >= 30 && qty < 100){
        total_val = qty * 1500
      }else if(qty >= 100 && qty < 200){
        total_val = qty * 1350
      }else if(qty >= 200 && qty < 300){
        total_val = qty * 1150
      }else if(qty >= 300 && qty < 500){
        total_val = qty * 1100
      }else if(qty >= 500){
        total_val = qty * 975
      }
      console.log(total_val)
      return total_val
    },
    calcWholesalePrice(qty){
      let ws_price = 1500;
      if(qty >= 30 && qty < 100){
        ws_price = 1500
      }else if(qty >= 100 && qty < 200){
        ws_price = 1350
      }else if(qty >= 200 && qty < 300){
        ws_price = 1150
      }else if(qty >= 300 && qty < 500){
        ws_price = 1100
      }else if(qty >= 500){
        ws_price = 975
      }
      return ws_price
    }
    // ...mapMutations(['UPDATE_SELECTED_USER']),
  },
  computed:{
    // ...mapState(['appdata']),
  },
  created(){
    this.products = this.selected_products
  }
}
</script>
<style scoped>
#ProductTable{
  margin: 1rem 0;
}
.icon{
  width: 1rem;
}
.close-btn{
  display: none;
  position: absolute;
  cursor:pointer;
  text-align: center;
  right: .5rem;
  top: .75rem;
  font-weight: bold;
  border-radius: 5px;
  padding: .1rem .5rem;
  border: 2px solid #be211b;
  background-color: #f6bebc;
  color: #be211b;
}
.product-row:hover .close-btn{
    display: block;
}
.product-row:hover {
  background-color: #fcfcfc;
}
.tag-icon{
  width: 2rem;
  margin: 0 .5rem;
}
.dates-created:hover .simp-date{
  position: absolute;
  transform: translateY(-12px);
  transition: 1s;
}
.dates-created:hover .fmt-date{
  display: block;
  position: absolute;
  transform: translateY(4px);
  transition: 1s;
}

/* DEPRECATED */
</style>
