import { createRouter, createWebHistory } from 'vue-router'
import store from '@/_store';
import Home from '../views/Home.vue'
import NotFound from '@/views/NotFound.vue'
// GATE
import GateAuth from '@/views/gate/GateAuth.vue'
import Register from '@/views/gate/Register.vue'
import AccountSetUp from '@/views/gate/AccountSetUp.vue'
import Login from '@/views/gate/Login.vue'
// PRODUCT
import CraftAnOrder from '@/views/our-products/CraftAnOrder.vue'
import OrderStatus from '@/views/our-products/OrderStatus.vue'
// DASHBOARD MENU
import InventoryMain from '@/views/inventory/InventoryMain.vue'
import HistoryMain from '@/views/history/HistoryMain.vue'
import MarketingMain from '@/views/marketing/MarketingMain.vue'
import AccountMain from '@/views/account/AccountMain.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {layout: 'default-layout'},
    component: Home
  },
  {
    path: '/order',
    name: 'CraftAnOrder',
    meta: {layout: 'default-layout'},
    component: CraftAnOrder
  },
  {
    path: '/order/:order_id',
    name: 'OrderStatus',
    meta: {layout: 'default-layout'},
    component: OrderStatus
  },
  {
    path: '/inventory',
    name: 'InventoryMain',
    meta: {layout: 'default-layout'},
    component: InventoryMain
  },
  {
    path: '/account',
    name: 'AccountMain',
    meta: {layout: 'default-layout'},
    component: AccountMain
  },
  {
    path: '/history',
    name: 'HistoryMain',
    meta: {layout: 'default-layout'},
    component: HistoryMain
  },
  {
    path: '/marketing-materials',
    name: 'MarketingMain',
    meta: {layout: 'default-layout'},
    component: MarketingMain
  },
  {
    path: '/auth',
    name: 'GateAuth',
    meta: {layout: 'no-layout'},
    component: GateAuth,
    redirect: '/register',
    children: [
      {
        path: '/register',
        name: 'Register',
        component: Register
      },
      {
        path: '/register/:token',
        name: 'AccountSetUp',
        component: AccountSetUp
      },
      {
        path: '/login',
        name: 'Login',
        component: Login
      },
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/not-found',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
    redirect: '/not-found',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const openRoutes = ['NotFound', 'GateAuth', 'Register', 'AccountSetUp', 'Login']//open for everyone
const redundantRoutes = ['Register', 'GateAuth', 'AccountSetUp', 'Login']

router.beforeEach(async (to, from, next) => {
  if (store.state.token != '') {
    if (redundantRoutes.includes(to.name)) {
      next({ name: 'Home' })
    } else {
      next()
    }
  } else if (openRoutes.includes(to.name) && store.state.token == '') {
      next()
  }else {
    next({ name: 'Login' })
  }
})

export default router
